<template>
  <pop-up :width="'50%'" :top="'70px'" 
                :backgroundStyle="{background:'none',alignItems:'flex-start'}" 
                :propStyle="{
                    boxShadow: `rgba(0, 0, 0, 0.8) 0px 0px 8px,
                                rgba(0, 0, 0, 0.7) 0px 0px 12px`,
                    borderRadius:'15px'            
                }" 
                :closePopupEvent="closePopupCB"
                :floater="true">
        <div :class="['root-div',{'result-active':displayResult}]">
           <form @submit.prevent="submitForm"> 
            <input
                type="text"
                placeholder="Search"
                class="search-box"
                @input="actInput($event)"
                v-bind:value="searchQuery"
                />


            <div class="results" v-show="displayResult" @click.stop>
            <div class="box">
                <ul v-if="results">
                <li
                    v-for="result in results"
                    :key="result.contentid"
                    @click="setRunQuery(result.title)"
                >{{result.title}}</li>
                </ul>
                <p v-if="localSearch">Previously Searched...</p>
                <ul v-if="localSearch">
                <li
                    v-for="result in localSearch"
                    :key="result.contentid"
                    @click="setRunQuery(result.title)"
                    class="previous-search"
                >{{result.title}}</li>
                </ul>
            </div>
            </div>
            </form>
        </div>

  </pop-up> 
</template>

<script>
export default {
name: "SearchFloat",    
props: ["closePopupCB",
        "showResults",
        "searchQuery",
        "setQuery",
        "searchInputCB",
        "searchSubmitCB",
        "results",
        "localSearch",
        "updateQuery"    
],
computed:{
    displayResult(){
        return this.showResults && this.searchQuery && this.searchQuery.length>2;
    }
},
methods:{
actInput($event){
    this.updateQuery($event.target.value);
    this.searchInputCB();
},
submitForm(){
    this.searchSubmitCB();
     this.closePopupCB();
},
setRunQuery(title){
    this.setQuery(title);
    this.closePopupCB();
}

},

beforeDestroy(){
    this.updateQuery("");
},

components: {
    "pop-up": () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue")
  }
}
</script>

<style scoped lang="scss">
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_variables.scss";
    .root-div{
        padding:0px 20px;

        &.result-active{ 
            .search-box{
                border-bottom:1px solid grey;
            }
        }

        li,p,input{
            font-family: $font-family;
            font-weight: $font-weight-medium;
            font-size:1.4rem;
            margin:10px 0px;
            color: $font-clr-white-1;
            cursor: pointer;
            &.previous-search{
                opacity: 0.6;
            }
        }
        li:hover{
            opacity: 1;
            color:$font-clr-white;
        }
    }
    .search-box{
        color:$clr-white !important;
        background: none;
        width: 100%;
        padding:10px 0px;
        border:0px;
    }

</style>